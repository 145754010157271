<script setup lang="ts">
import { USER_TYPE } from "@/constants";
import { PopoverGroup, Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import UserOptions from "@/components/marketing/UserOptions.vue";
import NavMenuItem from "@/layouts/partials/marketing/NavMenuItem.vue";

interface Emit {
  (event: "candidateAuthSelected"): void;
  (event: "employerRegisterSelected"): void;
}

interface Props {
  navigation: any[];
  dropDownMenus: any[];
}

const emit = defineEmits<Emit>();
defineProps<Props>();

const user = useSupabaseUser();
const route = useRoute();
const employerRoute = ref("");

const setEmployerRoute = (route: string) => {
  employerRoute.value = route;
};

const handleUser = (type: USER_TYPE) => {
  switch (type) {
    case USER_TYPE.employer:
      if (employerRoute.value === ROUTES.register) emit("employerRegisterSelected");
      navigateTo(employerRoute.value);
      break;
    case USER_TYPE.candidate:
      emit("candidateAuthSelected");
      break;
  }
};
</script>

<template>
  <div class="hidden lg:flex items-center justify-between w-full">
    <PopoverGroup class="flex lg:gap-x-5 xl:gap-x-8">
      <Popover v-for="(menu, index) in dropDownMenus" :key="index" v-slot="{ open }" class="relative">
        <PopoverButton
          class="flex items-center gap-x-1 text-sm font-semibold leading-6 outline-none rounded-lg hover:bg-primary/10 px-3 py-2"
          :class="{ 'bg-primary/10': route.path.includes(menu.basePath) }"
        >
          {{ menu.name }}
          <Icon
            name="fa6-solid:chevron-down"
            :class="{ 'rotate-180': open }"
            class="h-3 w-3 text-medium-gray transition-transform"
            aria-hidden="true"
          />
        </PopoverButton>

        <transition
          enter-active-class="transition ease-out duration-200"
          enter-from-class="opacity-0 translate-y-1"
          enter-to-class="opacity-100 translate-y-0"
          leave-active-class="transition ease-in duration-150"
          leave-from-class="opacity-100 translate-y-0"
          leave-to-class="opacity-0 translate-y-1"
        >
          <PopoverPanel
            class="absolute -left-8 top-full z-10 mt-3 w-screen max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-100"
          >
            <div class="p-2">
              <NavMenuItem v-for="item in menu.items" :key="item.name" :item="item" />
            </div>
            <div v-if="menu.cta" class="bg-gray-50 p-2">
              <NuxtLink :to="menu.cta.href">
                <PopoverButton
                  class="flex w-full items-center rounded-md p-3 group transition duration-150 ease-in-out hover:bg-gray-100 gap-x-6"
                >
                  <div class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-white">
                    <Icon :name="menu.cta.icon" class="h-6 w-6 group-hover:text-primary" aria-hidden="true" />
                  </div>
                  <div class="flex flex-col items-start">
                    <span class="text-base font-medium">{{ menu.cta.name }}</span>
                    <span class="mt-1 block text-sm text-gray-500">{{ menu.cta.desc }}</span>
                  </div>
                </PopoverButton>
              </NuxtLink>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
      <NuxtLink
        v-for="item in navigation"
        :key="item.name"
        :to="item.href"
        class="text-sm rounded-lg hover:bg-primary/10 px-3 py-2 font-semibold leading-6"
        :class="{ 'bg-primary/10': route.path === item.href }"
        >{{ item.name }}</NuxtLink
      >
    </PopoverGroup>

    <div class="hidden lg:flex justify-end flex-1">
      <Popover v-if="!user" class="relative gap-x-3 flex">
        <PopoverButton class="outline-none">
          <PrimaryButton @click="setEmployerRoute(ROUTES.login)">Sign in</PrimaryButton>
        </PopoverButton>
        <PopoverButton class="outline-none">
          <OutlineButton @click="setEmployerRoute(ROUTES.register)">Sign up</OutlineButton>
        </PopoverButton>

        <transition
          enter-active-class="transition ease-out duration-200"
          enter-from-class="opacity-0 translate-y-1"
          enter-to-class="opacity-100 translate-y-0"
          leave-active-class="transition ease-in duration-150"
          leave-from-class="opacity-100 translate-y-0"
          leave-to-class="opacity-0 translate-y-1"
        >
          <PopoverPanel
            class="absolute -right-8 top-full z-10 mt-3 w-screen max-w-2xl overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-100"
          >
            <div class="p-4">
              <PopoverButton class="w-full">
                <UserOptions @selected="(userType) => handleUser(userType)" />
              </PopoverButton>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>

      <NuxtLink v-else :to="ROUTES.dashboardHome"><PrimaryButton>Dashboard</PrimaryButton></NuxtLink>
    </div>
  </div>
</template>
